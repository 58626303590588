import React from "react";
import { useElement } from "../landing-page";

const getVideoId = link => {
  try {
    if (link.includes("youtube.com/watch?")) {
      return link.split("/watch?v=").pop();
    } else if (link.includes("youtu.be")) {
      return link.split("/").pop();
    }
  } catch (err) {
    return null;
  }
};

function Video({ name, ...props }) {
  const element = useElement(name);

  const src = element.src;

  if (!src) return null;

  const videoId = getVideoId(src);

  return (
    <div className="formkit-video" {...props}>
      <iframe
        width="100%"
        height="440"
        src={
          "https://www.youtube.com/embed/" +
          videoId +
          "?autoplay=0&color=white&modestbranding=1&rel=0"
        }
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

export default Video;
