import React from "react";
import Static from "./static";
import { useElement } from "../landing-page";

function Image({ name }) {
  const element = useElement(name);

  if (!element.src) return null;

  return <Static as="div" />;
}

export default Image;
