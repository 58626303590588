import React, { useContext } from "react";
import ReactDOM from "react-dom";
import stylesToJs from "../utils/styles-to-js";
import "./elements";

const LandingPageContext = React.createContext({});

export function useInfo() {
  return useContext(LandingPageContext);
}

export function useStyles() {
  const { styles } = useContext(LandingPageContext);
  const forElement = (name, defaults) => stylesToJs(name, styles, defaults);

  return { forElement };
}

export function useElement(name) {
  const context = useContext(LandingPageContext);

  return context.elements.find(element => element.name === name) || {};
}

const custom = element => element.type === "Custom";
const inputs = element => element.type === "Input" || custom(element);

export const useElements = () => {
  const { elements } = useContext(LandingPageContext);

  return {
    all: elements,
    custom: elements.filter(custom),
    inputs: elements.filter(inputs)
  };
};

export function useSetting(name) {
  const context = useContext(LandingPageContext);

  return context.settings.find(setting => setting.name === name) || {};
}

function Wrapper({ children }) {
  const styles = useStyles();
  const elements = useElements();

  return React.cloneElement(children, { elements, styles });
}

function LandingPage({
  id,
  uid,
  action,
  children,
  elements = [],
  settings = [],
  styles = []
}) {
  return (
    <LandingPageContext.Provider
      value={{ id, uid, action, elements, settings, styles }}
    >
      <Wrapper>{children}</Wrapper>
    </LandingPageContext.Provider>
  );
}

if (document && document.body.dataset.template) {
  const template = document.body.dataset.template;
  const Template = window.__templates[template];
  const node = document.body.children[0];

  const props = window.__props || { elements: [], settings: [], styles: [] };

  ReactDOM.hydrate(
    <LandingPage {...props}>
      <Template />
    </LandingPage>,
    node
  );
}

export default LandingPage;
