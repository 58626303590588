import React from "react";
import global from "./utils/global";

function createDefault(name) {
  return ({ children }) => (
    <div>{children || `No component provided for '${name}'`}</div>
  );
}

export function register(name, component) {
  if (typeof global !== "undefined" && !global.__components) {
    global.__components = {};
  }

  global.__components[name] = component;
}

export function useComponents() {
  const handler = {
    get: function(_, name) {
      if (typeof global !== "undefined") {
        if (name in global.__components) {
          return global.__components[name];
        }
      }

      return createDefault(name);
    }
  };

  const proxy = new Proxy({}, handler);

  return proxy;
}
