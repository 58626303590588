import React, { useLayoutEffect, useRef } from "react";
import Errors from "./errors";
import Image from "./image";
import Video from "./video";
import Static from "./static";
import { register } from "../../components";
import { useInfo } from "../landing-page";

let registered = false;
function registerWithCKJS(data) {
  if (registered) return;
  window.__sv_forms = [data];
  registered = true;
}

export const Form = ({ style, children, ...props }) => {
  const { id, uid, action, settings } = useInfo();
  const form = useRef();

  useLayoutEffect(() => {
    registerWithCKJS({
      element: form.current,
      id,
      uid,
      template: "",
      settings
    });
  }, []);

  return (
    <form
      ref={form}
      method="POST"
      action={action}
      style={style}
      data-sv-form={id}
      data-uid={uid}
      className="formkit-form"
      {...props}
    >
      {children}
    </form>
  );
};

const LandingPage = ({ children, style, uid }) => {
  return (
    <div className="formkit-page" style={style} data-uid={uid}>
      {children}
    </div>
  );
};

export default LandingPage;

register("Form", Form);
register("LandingPage", LandingPage);
register("AddFieldButton", () => null);
register("Button", ({ onClick }) => <Static as="button" onClick={onClick} />);
register("CustomFields", () => <Static as="div" />);
register("Content", () => <Static as="div" />);
register("Errors", Errors);
register("Event", () => <Static as="div" />);
register("Heading", () => <Static as="div" />);
register("Image", Image);
register("PoweredBy", () => <Static as="a" />);
register("Video", Video);
